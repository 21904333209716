import _ from 'lodash';
import { TipoColunaConst } from '@const/TipoColunaConst';
import { SentidoConst } from '@const/SentidoConst';
export class CalibracaoTabelaModel {
  constructor(
    {
      id,
      colunas = [],
      linhas = [],
      instrumentosPadroes = [],
      instrumentosAlterados,
      formulaMensurandoId,
      tabelaId,
      unidadeMedidaSistema,
    },
    calibracaoId = null
  ) {
    this.id = id;
    this.tabelaId = tabelaId;
    this.colunas = colunas.sort((a, b) => a.sequencia - b.sequencia);
    this.linhas = linhas.sort(
      (a, b) =>
        a.pontoCalibracao.valor - b.pontoCalibracao.valor ||
        a.sequencia - b.sequencia
    );
    this.instrumentosPadroes = instrumentosPadroes;
    this.instrumentosAlterados = instrumentosAlterados;
    this.calibracaoId = calibracaoId;
    this.formulaMensurandoId = formulaMensurandoId;
    this.unidadeMedidaSistema = unidadeMedidaSistema;

    this.popularValores();
    this.calcularTabulacao();
  }

  popularValores() {
    let colunasComSentido = this.colunas.filter(
      (coluna) =>
        coluna.tipoColuna == TipoColunaConst.SMC ||
        coluna.tipoColuna == TipoColunaConst.SMP
    );

    this.linhas.forEach((linha) => {
      colunasComSentido.forEach((coluna) => {
        if (!linha.valores.find((el) => el.tabelaColunaId == coluna.id))
          linha.valores.push({
            tabelaColunaId: coluna.id,
            valor: '',
          });
        let valor = linha.valores.find((el) => el.tabelaColunaId == coluna.id);
        valor.tipoColuna = coluna.tipoColuna;
        valor.pontoCalibracaoId = linha.pontoCalibracao.id;
        valor.formulaVariavelId = coluna.formulaVariavel?.id;
      });

      linha.resultados = [];
      this.colunas
        .filter((coluna) => coluna.tipoColuna == TipoColunaConst.RESULTADO)
        .forEach((coluna) => {
          let valor = linha.valores.find(
            (el) => el.tabelaColunaId == coluna.id
          );
          if (valor !== undefined)
            linha.resultados.push({
              id: valor.tabelaColunaId,
              valor: valor.valor ?? '',
            });
        });
    });
  }

  calcularTabulacao() {
    let colunasComSentido = this.colunas.filter(
      (coluna) =>
        coluna.tipoColuna == TipoColunaConst.SMC ||
        coluna.tipoColuna == TipoColunaConst.SMP
    );
    if (!colunasComSentido.length || !this.linhas.length) return;

    let qtdLinhas = this.linhas.length;
    let qtdColunas = colunasComSentido.length;
    let primeiroInput = 0;
    if (colunasComSentido[0].sentido == SentidoConst.DeBaixoParaCima || colunasComSentido[0].sentido == SentidoConst.DeBaixoParaCimaProximaTabela)
      primeiroInput = qtdLinhas - 1;

    let indiceTabulacao = 1;
    let posicaoLinha = primeiroInput;
    let posicaoColuna = 0;
    let finalizado = false;
    while (!finalizado) {
      this.linhas[posicaoLinha].valores[posicaoColuna].indiceTabulacao =
        indiceTabulacao;

      indiceTabulacao++;
      switch (colunasComSentido[posicaoColuna].sentido) {
        case SentidoConst.DeBaixoParaCimaProximaTabela:
        case SentidoConst.DeBaixoParaCima:
          posicaoLinha--;
          if (posicaoLinha < 0) {
            if (
              colunasComSentido[posicaoColuna].sentido ==
              SentidoConst.DeBaixoParaCimaProximaTabela
            )
              this.linhas[posicaoLinha + 1].valores[
                posicaoColuna
              ].voltarTabela = true;

            posicaoColuna++;
            if (posicaoColuna == qtdColunas) {
              this.linhas[0].valores[posicaoColuna - 1].ultimo = true;
              finalizado = true;
              continue;
            }
            posicaoLinha = 0;
            if (
              colunasComSentido[posicaoColuna].sentido ==
                SentidoConst.DeBaixoParaCima ||
              colunasComSentido[posicaoColuna].sentido ==
                SentidoConst.DeBaixoParaCimaProximaTabela
            )
              posicaoLinha = qtdLinhas - 1;
          }
          break;
        case SentidoConst.DeCimaParaBaixoProximaTabela:
        case SentidoConst.DeCimaParaBaixo:
          posicaoLinha++;
          if (posicaoLinha == qtdLinhas) {
            if (
              colunasComSentido[posicaoColuna].sentido ==
              SentidoConst.DeCimaParaBaixoProximaTabela
            )
              this.linhas[posicaoLinha - 1].valores[
                posicaoColuna
              ].proximaTabela = true;

            posicaoColuna++;
            if (posicaoColuna == qtdColunas) {
              this.linhas[posicaoLinha - 1].valores[
                posicaoColuna - 1
              ].ultimo = true;
              finalizado = true;
              continue;
            }
            posicaoLinha = 0;
            if (
              colunasComSentido[posicaoColuna].sentido ==
                SentidoConst.DeBaixoParaCima ||
              colunasComSentido[posicaoColuna].sentido ==
                SentidoConst.DeBaixoParaCimaProximaTabela
            )
              posicaoLinha = qtdLinhas - 1;
          }

          break;
        case SentidoConst.DaEsquerdaParaDireita:
          posicaoColuna++;
          if (posicaoColuna == qtdColunas) {
            posicaoLinha++;
            posicaoColuna--;
            if (posicaoLinha == qtdLinhas) {
              this.linhas[posicaoLinha - 1].valores[
                posicaoColuna
              ].ultimo = true;
              finalizado = true;
              continue;
            }

            posicaoColuna = 0;
            while (posicaoColuna < qtdColunas) {
              if (
                this.linhas[posicaoLinha].valores[posicaoColuna]
                  .indiceTabulacao === undefined
              )
                break;
              posicaoColuna++;
            }
          }
          break;
      }
    }
  }

  get request() {
    let retorno = _.cloneDeep(this);
    return {
      tabelaId: retorno.tabelaId,
      linhas: retorno.linhas.map((el) => {
        let valores = el.valores
          .filter(
            (v) =>
              v.tipoColuna == TipoColunaConst.SMP ||
              v.tipoColuna == TipoColunaConst.SMC
          )
          .map((valor) => {
            return {
              colunaId: valor.tabelaColunaId,
              valor: valor.valor,
              instrumentoPadraoId: retorno.instrumentosPadroes.find(
                (instrumento) =>
                  instrumento.instrumentoFaixaPontoId ==
                    valor.pontoCalibracaoId &&
                  instrumento.formulaVariavel.id == valor.formulaVariavelId
              )?.instrumento?.id,
            };
          });

        valores.push(
          ...el.resultados.map((r) => {
            return {
              colunaId: r.id,
              valor: r.valor,
            };
          })
        );
        return {
          calibracaoLinhaId: el.id,
          valores: valores,
        };
      }),
      instrumentosPadroes: retorno.instrumentosPadroes.map((el) => {
        return {
          id: el.id,
          instrumentoId: el.instrumento?.id,
          faixaId: el.faixa?.id
        };
      }),
    };
  }
}
